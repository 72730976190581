import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GlobalContext } from '../context/GlobalContextComp';
import { Link } from 'react-router-dom';

export default function Oauth() {
    const [url] = useState(window.location);

    const { globalState, setGlobalState } = useContext(GlobalContext);

    const [searchParams] = useSearchParams(url);

    console.log(searchParams.get('code'), url);

    useEffect(() => {
        const newState = { ...globalState };
        newState.user.authCode = searchParams.get('code');
        if (newState.user.authCode) {
            setGlobalState(newState);
            localStorage.setItem('auth_code', searchParams.get('code'));
            const reUri = window.location.origin + '/onboarding';
            console.log(newState.user.authCode);
            window.location.href = reUri;
            setTimeout(() => {
                console.log('In timeout');
            }, 8000);
        }
    });

    return (
        <h1 className="text-3xl font-bold underline">
            Hey! if refresh doesnt happens soon
            <br />
            <Link to={'/onboarding'}>Click Here</Link>
        </h1>
    );
}
