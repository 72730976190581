import React, { useRef, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';
import { GlobalContext } from '../context/GlobalContextComp';
import createOnboarding from '../services/onboarding.service';
import { toast } from 'react-toastify';

const fileTypes = ['JPG', 'PNG', 'GIF'];

export default function Onboarding() {
    const { globalState, setGlobalState, onBoardingState, setOnBoardingState } =
        useContext(GlobalContext);
    const useGhlAccessTokenRef = useRef(globalState.user.authCode);
    const useGhlLocationIdRef = useRef();
    const useGhlPublicApiKeyRef = useRef();
    // const useTwilioAuthTokenRef = useRef();
    // const useTwilioSidRef = useRef();
    const useLocationNameRef = useRef();
    const useImageUrlRef = useRef();
    // const { user } = useAuth();

    const [file, setFile] = useState(null);

    const handleFileChange = (file) => {
        setFile(file);
    };

    const showToastMessage = (
        isError = true,
        message = 'UnauthorizedError',
    ) => {
        if (isError) {
            return toast.error(message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }

        setGlobalState((state) => {
            return { ...state, user: { ...state.user, authCode: null } };
        });
        useGhlAccessTokenRef.current.value = null;

        useGhlLocationIdRef.current.value = null;
        useGhlPublicApiKeyRef.current.value = null;
        // useTwilioAuthTokenRef.current.value = null;
        // useTwilioSidRef.current.value = null;
        useGhlLocationIdRef.current.value = null;
        useLocationNameRef.current.value = null;
        useImageUrlRef.current.value = null;
        return toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    };

    const presistStorage = () => {
        let data = {
            ghlLocationId: useGhlLocationIdRef.current.value,
            ghlPublicApiKey: useGhlPublicApiKeyRef.current.value,
            // twilioAuthToken: useTwilioAuthTokenRef.current.value,
            // twilioSid: useTwilioSidRef.current.value,
            locationId: useGhlLocationIdRef.current.value,
            locationName: useLocationNameRef.current.value,
            imageUrl: useImageUrlRef.current.value,
        };
        localStorage.setItem('onBoardingState', JSON.stringify(data));
        setOnBoardingState(data);
    };

    const handleSubmit = async () => {
        setGlobalState((state) => {
            return { ...state, locationId: useGhlLocationIdRef.current.value };
        });
        let data = {
            ghlLocationId: useGhlLocationIdRef.current.value,
            ghlAccessCode: useGhlAccessTokenRef.current.value,
            ghlPublicApiKey: useGhlPublicApiKeyRef.current.value,
            // twilioAuthToken: useTwilioAuthTokenRef.current.value,
            // twilioSid: useTwilioSidRef.current.value,
            locationId: useGhlLocationIdRef.current.value,
            locationName: useLocationNameRef.current.value,
            imageUrl: useImageUrlRef.current.value,
        };
        if (file) {
            data.file = file;
        }
        const onBoardingResponse = await createOnboarding(data);
        if (onBoardingResponse.success == true) {
            showToastMessage(false, onBoardingResponse.message);
        } else {
            showToastMessage(true, 'Onboarding failed');
        }
    };

    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const codeParam = urlParams.get('code');
    
        if (codeParam) {
            useGhlAccessTokenRef.current.value = codeParam;
        }
        if (localStorage.getItem('onBoardingState')) {
            const data = JSON.parse(localStorage.getItem('onBoardingState'));
            // useGhlAccessTokenRef.current.value = globalState.user.auth_Code;
            console.log(globalState.user.auth_Code);
            useGhlLocationIdRef.current.value = data.ghlLocationId;
            useGhlPublicApiKeyRef.current.value = data.ghlPublicApiKey;
            // useTwilioAuthTokenRef.current.value = data.twilioAuthToken;
            // useTwilioSidRef.current.value = data.twilioSid;
            useLocationNameRef.current.value = data.locationName;
            useImageUrlRef.current.value = data.imageUrl;
            setOnBoardingState(data);
        }
    }, [0]);

    return (
        <section className="text-gray-600 body-font relative">
            <div className="container px-5 py-24 mx-auto">
                <div className="flex flex-col text-center w-full mb-12">
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
                        Onboarding
                    </h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                        Enter details below to setup cron jobs
                    </p>
                </div>

                {/* Form starts below */}
                <div className="lg:w-1/2 md:w-2/3 mx-auto">
                    <div className="flex flex-wrap -m-2">
                        <div className="p-2 w-1/2">
                            <div className="relative">
                                <label
                                    htmlFor="ghlLocationId"
                                    className="leading-7 text-sm text-gray-600">
                                    GHL Location Id
                                </label>
                                <input
                                    type="text"
                                    id="ghlLocationId"
                                    ref={useGhlLocationIdRef}
                                    onChange={presistStorage}
                                    name="ghlLocationId"
                                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                />
                            </div>
                        </div>
                        <div className="p-2 w-1/2">
                            <div className="relative">
                                <label
                                    htmlFor="ghlAccessToken"
                                    className="leading-7 text-sm text-gray-600">
                                    GHL Access Token
                                </label>
                                <input
                                    type="text"
                                    id="ghlAccessToken"
                                    ref={useGhlAccessTokenRef}
                                    // value={globalState.user.auth_Code}
                                    disabled
                                    // value={globalState.user.auth_Code}
                                    // defaultValue={}
                                    name="ghlAccessToken"
                                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                />
                            </div>
                        </div>
                        <div className="p-2 w-1/2">
                            <div className="relative">
                                <label
                                    htmlFor="ghlPublicApiKey"
                                    className="leading-7 text-sm text-gray-600">
                                    GHL Public API Key
                                </label>
                                <input
                                    type="text"
                                    id="ghlPublicApiKey"
                                    ref={useGhlPublicApiKeyRef}
                                    onChange={presistStorage}
                                    name="ghlPublicApiKey"
                                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                />
                            </div>
                        </div>
                        {/* <div className="p-2 w-1/2">
                            <div className="relative">
                                <label
                                    htmlFor="twilioAuthToken"
                                    className="leading-7 text-sm text-gray-600">
                                    Twilio Auth Token
                                </label>
                                <input
                                    type="text"
                                    id="twilioAuthToken"
                                    ref={useTwilioAuthTokenRef}
                                    onChange={presistStorage}
                                    name="twilioAuthToken"
                                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                />
                            </div>
                        </div>
                        <div className="p-2 w-1/2">
                            <div className="relative">
                                <label
                                    htmlFor="twilioSid"
                                    className="leading-7 text-sm text-gray-600">
                                    Twilio SID
                                </label>
                                <input
                                    type="text"
                                    id="twilioSid"
                                    ref={useTwilioSidRef}
                                    onChange={presistStorage}
                                    name="twilioSid"
                                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                />
                            </div>
                        </div> */}
                        <div className="p-2 w-1/2">
                            <div className="relative">
                                <label
                                    htmlFor="locationName"
                                    className="leading-7 text-sm text-gray-600">
                                    Location Name
                                </label>
                                <input
                                    type="text"
                                    id="locationName"
                                    ref={useLocationNameRef}
                                    onChange={presistStorage}
                                    name="locationName"
                                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                />
                            </div>
                        </div>
                        <div className="p-2 w-full">
                            <div className="relative">
                                <label
                                    htmlFor="locationName"
                                    className="leading-7 text-sm text-gray-600">
                                    Image URL
                                </label>
                                <input
                                    type="text"
                                    id="imageUrl"
                                    ref={useImageUrlRef}
                                    onChange={presistStorage}
                                    name="imageUrl"
                                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                />
                            </div>
                        </div>
                        {/* <div className="p-2 w-full">
                            <div className="relative flex flex-col items-center">
                                <FileUploader
                                    handleChange={handleFileChange}
                                    name="file"
                                    types={fileTypes}
                                />
                            </div>
                        </div> */}

                        <div className="p-2 w-full">
                            <button
                                onClick={handleSubmit}
                                className="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                                Validitate Creadentials and Submit
                            </button>
                        </div>
                        <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
                            <Link
                                reloadDocument={true}
                                to={'/redirect'}
                                className="leading-normal my-5 text-indigo-500">
                                Click here to grab GHL Access Token.
                            </Link>
                        </div>
                    </div>
                </div>

                {/* form ends above */}
            </div>
        </section>
    );
}
