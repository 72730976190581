import React, { useCallback, useContext, useEffect } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import axios from 'axios';
import { getWeeksBetween } from '../services/utils.service';
import { GlobalContext } from '../context/GlobalContextComp';
import moment from 'moment';

const apiBaseUrl = process.env.REACT_APP_apiUrl;

export default function PersonalReport(props) {
    const { locationId } = props;
    const [dates, changeDates] = React.useState({});

    const { dashboardDates, setdates, setAverageProspectSize, setSoldCases } =
        useContext(GlobalContext);

    // const weekData = getWeeksBetween(
    //     dashboardDates.startDate,
    //     dashboardDates.endDate,
    // );
    // const [weeksBetween] = React.useState(weekData);
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [requestInProcess, toggleRequestInProcess] = React.useState(false);

    const [reportData, setReportData] = React.useState();

    useEffect(() => {
        getApiData();
    }, [locationId]);

    const formatDate = (date) => {
        const dateArr = date.split('-');
        const yr = dateArr[0];
        const mm = dateArr[1].length == 1 ? `0${dateArr[1]}` : dateArr[1];
        const dd = dateArr[2].length == 1 ? `0${dateArr[2]}` : dateArr[2];

        return `${yr}-${mm}-${dd}`;
    };

    const handleDateValueChange = async (newValue) => {
        // if (requestInProcess) return;
        // alert('fdfgg');
        const strtDate = newValue.startDate ? new Date(formatDate(newValue.startDate)) : '';
        const endDate = newValue.endDate ? new Date(formatDate(newValue.endDate)) : '';

        if (!strtDate || !endDate) {
            console.log('No value selected');
            // getNewDataCallBack(undefined, undefined);
            getApiData();
            changeDates({
                startDate: '',
                endDate: '',
            });
            return;
        }
        else{
            console.log('dates are:');
            console.log(strtDate, endDate);
            setdates({
                startDate: strtDate,
                endDate: endDate,
            })
        }

        // setDashboardDates(newValue);
        // getNewDataCallBack(strtDate, endDate);
        await changeDates({
            startDate: strtDate ? strtDate : '',
            endDate: endDate ? endDate : '',
        });

        getApiData(strtDate, endDate);
    };

    const getApiData = (startDate = "", endDate = "") => {
        toggleRequestInProcess(true);
        // alert('sdfsdf');
        let requestBody = {
            startDate: startDate,
            endDate: endDate,
        };
        if (locationId) requestBody.locationId = locationId;
        console.log(requestBody);
        axios.post(`${apiBaseUrl}/reportings/personalReport`, requestBody).then(
            (result) => {
                setIsLoaded(true);
                console.log(result);
                setReportData(result.data);

                setAverageProspectSize(
                    parseFloat(result.data.averageProspectSize).toFixed(2),
                );
                setSoldCases(result.data.totalRATSoldCase);

                toggleRequestInProcess(false);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                setIsLoaded(true);
                setError(error);
            },
        );
    };

    // const getNewDataCallBack = useCallback(() => {
    //     getApiData();
    // }, [locationId, dashboardDates.endDate, dashboardDates.startDate]);

    // React.useEffect(() => {
    //     getNewDataCallBack();
    // }, [getNewDataCallBack, locationId]);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else
        return (
            <section className="text-gray-600 body-font relative my-4">
                <div className="container mx-auto">
                    <div className="flex p-8 justify-between font-semibold bg-black text-white">
                        {/* top black bar started */}
                        <div>Will</div>
                        {/* <div>Fun Facts</div> */}
                        {/* top black bar ended */}
                    </div>

                    <div className="flex justify-between">
                        <div className="flex flex-col w-full bg-red-600">
                            <div className="flex">
                                <div className="p-2 lg:w-1/6 sm:w-1/2 w-full bg-red-600">
                                    {/* left light red bar started */}
                                    <h2 className="text-center font-bold p-4 text-gray-200">
                                        DATE SPECIFIC PQL
                                    </h2>
                                    <div className="flex flex-col space-between p-4">
                                        <div className="flex justify-center text-xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100 text-gray-700 m-2">
                                            PQL LEADS: <br />
                                            {reportData.R_AT_PQL_Leads}
                                        </div>
                                        <div className="flex justify-center text-xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100 text-gray-700 m-2">
                                            Average New PQL Per Week: <br />
                                            {/* {(
                                                reportData.totalRATPQLLeads /
                                                reportData.totalWeeks
                                                
                                            ).toFixed(2)} */}
                                            {reportData.PQL_AVG_PER_WEEK}
                                        </div>
                                    </div>
                                    {/* left light red bar ended */}
                                </div>
                                <div className="p-4 pt-2 lg:w-5/6 sm:w-1/2 w-full bg-red-800 flex flex-col justify-evenly">
                                    {/* middle section started */}
                                    <div className="flex flex-row">
                                        <h2 className="text-center font-bold text-gray-200">
                                            DATE SPECIFIC PIPELINE
                                        </h2>
                                        <Datepicker
                                            containerClassName="flex flex-wrap"
                                            value={dashboardDates}
                                            showShortcuts={true}
                                            showFooter={true}
                                            displayFormat={'MM/DD/YYYY'}
                                            readOnly={false}
                                            onChange={handleDateValueChange}
                                        />
                                    </div>

                                    <div className="container mx-auto">
                                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
                                            <div className="flex flex-col items-center justify-center text-xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100 text-gray-700">
                                                <div>
                                                    LOF Leads: <br />
                                                </div>
                                                <div>
                                                    {
                                                        reportData.R_AT_LOF_Leads
                                                    }
                                                </div>
                                            </div>
                                            <div className="flex flex-col items-center justify-center text-xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100 text-gray-700">
                                                <div>
                                                    LOF Calls Made: <br />
                                                </div>
                                                <div>
                                                    {
                                                        reportData.R_AT_LOF_CALLS_MADE
                                                    }
                                                </div>
                                            </div>
                                            <div className="flex flex-col items-center justify-center text-xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100 text-gray-700">
                                                <div>
                                                    DM Contacts: <br />{' '}
                                                </div>
                                                <div>
                                                    {
                                                        reportData.R_AT_DM_Contacts
                                                    }{' '}
                                                </div>
                                            </div>
                                            <div className="flex flex-col items-center justify-center text-xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100 text-gray-700">
                                                <div>
                                                    DM Scheduled: <br />{' '}
                                                </div>{' '}
                                                <div>
                                                    {
                                                        reportData.R_AT_DM_Scheduled
                                                    }
                                                </div>{' '}
                                            </div>
                                            <div className="flex flex-col items-center justify-center text-xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100 text-gray-700">
                                                <div>
                                                    DM Ran: <br />{' '}
                                                </div>
                                                <div>
                                                    {reportData.R_AT_DM_Ran}
                                                </div>{' '}
                                            </div>
                                            <div className="flex flex-col items-center justify-center text-xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100 text-gray-700">
                                                <div>
                                                    {' '}
                                                    BAMFAM Ran: <br />{' '}
                                                </div>
                                                <div>
                                                    {
                                                        reportData.R_AT_BAMFAM_Ran
                                                    }
                                                </div>{' '}
                                            </div>
                                            <div className="flex flex-col items-center justify-center text-xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100 text-gray-700">
                                                <div>
                                                    {' '}
                                                    Sold Cases: <br />{' '}
                                                </div>
                                                <div>
                                                    {
                                                        reportData.R_AT_Sold_Case
                                                    }
                                                </div>{' '}
                                            </div>
                                            <div className="flex flex-col items-center justify-center text-xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100 text-gray-700">
                                                <div>
                                                    {' '}
                                                    LTFU: <br />{' '}
                                                </div>
                                                <div>
                                                    {reportData.R_AT_LTFU}
                                                </div>{' '}
                                            </div>
                                            <div className="flex flex-col items-center justify-center text-xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100 text-gray-700">
                                                <div>
                                                    {' '}
                                                    Good No!: <br />{' '}
                                                </div>
                                                <div>
                                                    {reportData.R_AT_Good_No}
                                                </div>{' '}
                                            </div>
                                            <div className="flex flex-col items-center justify-center text-xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100 text-gray-700">
                                                <div>
                                                    {' '}
                                                    Current Lala Land: <br />{' '}
                                                </div>
                                                <div>
                                                    {
                                                        reportData.R_AT_Lala_Land
                                                    }
                                                </div>{' '}
                                            </div>
                                        </div>
                                    </div>
                                    {/* middle section ended */}
                                </div>
                            </div>
                            <div className="flex p-4 justify-between font-semibold bg-black text-white">
                                SKILL
                            </div>
                            <div className="flex">
                                <div className="p-4 lg:w-1/6 sm:w-1/2 w-full bg-red-600">
                                    {/* left light red bar started */}
                                    <div className="flex flex-col space-between p-4">
                                        <div className="flex justify-center text-xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100 text-gray-700 m-2">
                                            DM Contacts to DM Scheduled: <br />
                                            {/* {reportData.totalRATDMContacts} */}
                                            {isNaN(
                                                    reportData.R_AT_DM_Contacts /
                                                        reportData.R_AT_DM_Scheduled,
                                                ) ||
                                                !(
                                                    reportData.R_AT_DM_Contacts /
                                                    reportData.R_AT_DM_Scheduled
                                                )
                                                    ? 0
                                                    : (
                                                          (reportData.R_AT_DM_Scheduled /
                                                              reportData.R_AT_DM_Contacts) *
                                                          100
                                                      ).toFixed(2)}
                                                %
                                        </div>
                                    </div>
                                    {/* left light red bar ended */}
                                </div>
                                <div className="p-4 pt-5 lg:w-5/6 sm:w-1/2 w-full bg-red-800 flex">
                                    {/* skill middle section started */}
                                    <div className="container mx-auto">
                                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
                                            <div className="flex justify-center text-xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100 text-gray-700">
                                                DM Scheduled to DM Ran: <br />
                                                {isNaN(
                                                    reportData.R_AT_DM_Scheduled /
                                                        reportData.R_AT_DM_Ran,
                                                ) ||
                                                !(
                                                    reportData.R_AT_DM_Scheduled /
                                                    reportData.R_AT_DM_Ran
                                                )
                                                    ? 0
                                                    : (
                                                          (reportData.R_AT_DM_Ran /
                                                              reportData.R_AT_DM_Scheduled) *
                                                          100
                                                      ).toFixed(2)}
                                                %
                                            </div>
                                            <div className="flex justify-center text-xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100 text-gray-700">
                                                DM Ran to BAMFAM: <br />
                                                {isNaN(
                                                    reportData.R_AT_DM_Ran /
                                                        reportData.R_AT_BAMFAM_Ran,
                                                ) ||
                                                !(
                                                    reportData.R_AT_DM_Ran /
                                                    reportData.R_AT_BAMFAM_Ran
                                                )
                                                    ? 0
                                                    : (
                                                          (reportData.R_AT_BAMFAM_Ran /
                                                              reportData.R_AT_DM_Ran) *
                                                          100
                                                      ).toFixed(2)}
                                                %
                                            </div>
                                            <div className="flex justify-center text-xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100 text-gray-700">
                                                BAMFAM to Sold Case: <br />
                                                {isNaN(
                                                    reportData.R_AT_Sold_Case /
                                                        reportData.R_AT_BAMFAM_Ran,
                                                ) ||
                                                !(
                                                    reportData.R_AT_Sold_Case /
                                                    reportData.R_AT_BAMFAM_Ran
                                                ) ||
                                                reportData.R_AT_Sold_Case /
                                                    reportData.R_AT_BAMFAM_Ran ==
                                                    'Infinity'
                                                    ? 0
                                                    : (
                                                          (reportData.R_AT_Sold_Case /
                                                              reportData.R_AT_BAMFAM_Ran) *
                                                          100
                                                      ).toFixed(2)}
                                                %
                                            </div>
                                            <div className="flex justify-center text-xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100 text-gray-700">
                                                LOF Lead to DM Scheduled: <br />
                                                {isNaN(
                                                    reportData.R_AT_DM_Scheduled /
                                                        reportData.R_AT_LOF_Leads,
                                                ) ||
                                                !(
                                                    reportData.R_AT_DM_Scheduled /
                                                    reportData.R_AT_LOF_Leads
                                                ) ||
                                                reportData.R_AT_DM_Scheduled /
                                                    reportData.R_AT_LOF_Leads ==
                                                    'Infinity'
                                                    ? 0
                                                    : (
                                                          (reportData.R_AT_DM_Scheduled /
                                                              reportData.R_AT_LOF_Leads) *
                                                          100
                                                      ).toFixed(2)}
                                                %
                                            </div>
                                            <div className="flex justify-center text-xl border-2 border-gray-300 rounded-xl p-6 bg-gray-100 text-gray-700">
                                                Avg Days from LOF to DM Contact:{' '}
                                                <br />
                                                {isNaN(
                                                    reportData.avg_date_for_LOF_to_DM_Contact,
                                                )
                                                    ? 0
                                                    : parseInt(
                                                          reportData.avg_date_for_LOF_to_DM_Contact,
                                                      )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* skill middle section ended */}
                                </div>
                            </div>
                        </div>

                        {/* <div className="p-2 lg:w-1/6 sm:w-1/2 w-full bg-blue-600"> */}
                        {/* right blue bar started */}
                        {/* <div>Hi</div> */}
                        {/* <div>My name is...</div> */}
                        {/* right blue bar ended */}
                        {/* </div> */}
                    </div>
                </div>
            </section>
        );
}
