import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_apiUrl;

export default async function createOnboarding(data) {
    try {
        //  make multipart/form-data request
        let formData = new FormData();
        formData.append('ghlAccessCode', data.ghlAccessCode);
        formData.append('ghlLocationId', data.ghlLocationId);
        formData.append('ghlPublicApiKey', data.ghlPublicApiKey);
        // formData.append('twilioAuthToken', data.twilioAuthToken);
        // formData.append('twilioSid', data.twilioSid);
        formData.append('locationId', data.locationId);
        formData.append('locationName', data.locationName);
        if (data.imageUrl) {
            formData.append('imageUrl', data.imageUrl)
        }

        // if (data.file) {
        //     let imageData = new FormData();
        //     imageData.append('locationId', data.locationId);
        //     imageData.append('ghlLocationId', data.ghlLocationId);
        //     imageData.append('file', data.file);


        //     const imageUpload = await axios({
        //         method: 'post',
        //         url: `${apiBaseUrl}/newLocation/image?locationId=${data.locationId}`,
        //         data: imageData,
        //         headers: { 'Content-Type': 'multipart/form-data' },
        //     }).then((res) => {
        //         console.log(res.data);
        //         return res.data;
        //     })
        //     .catch((err) => {
        //         console.error(err);
        //         return err;
        //     });

        //     console.log(imageUpload);
        // }


        const locationData = await axios({
            method: 'post',
            url: `${apiBaseUrl}/validation`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
            }).then((res) => {
                console.log(res.data);
                return res.data;
            })
            .catch((err) => {
                console.error(err);
                return err;
            });

            return locationData;
    } catch (error) {
        console.log(error);
        return error;
    }
}
