import Onboarding from './components/onboarding';
import Oauth from './components/oauth';
import Redirect from './components/redirect';
import Board from './components/board';
import LoginPage from './pages/loginPage';
import DashBoardPage from './pages/dashboardPage';
import PersonalReport from './components/personalReport';
import { ProtectedRoute } from './components/login/protectedRoute';

const routes = [
    {
        path: '/',
        element: <LoginPage />,
    },
    {
        path: '/onboarding',
        element: (
            // <ProtectedRoute>
                <Onboarding />
            // </ProtectedRoute>
        ),
    },
    {
        path: '/redirect',
        element: <Redirect />,
    },
    {
        path: '/oauth/callback/',
        element: (
            // <ProtectedRoute>
                <Oauth />
            // </ProtectedRoute>
        ),
    },
    {
        path: '/login',
        element: <LoginPage />,
    },
    {
        path: '/dashboard',
        element: (
            // <ProtectedRoute>
                <DashBoardPage />
            // </ProtectedRoute>
        ),
    },
    {
        path: '/board',
        element: (
            // <PersonalReport/>
            <DashBoardPage />
            // <ProtectedRoute>
            // </ProtectedRoute>
        ),
    },
    {
        path: '/test',
        element: (
            <Board/>
        ),
    },
];
export default routes;
