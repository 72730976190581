import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_apiUrl;

export async function createUser(data) {
    try {
        return await axios
            .post(`${apiBaseUrl}/signup`, data)
            .then((res) => res.data);
    } catch (error) {
        console.log(error);
        return error.response;
    }
}

export async function signInUser(data) {
    try {
        return await axios
            .post(`${apiBaseUrl}/users/login`, data)
            .then((res) => res.data);
    } catch (error) {
        console.log(error);
        return error.response;
    }
}
