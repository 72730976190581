import React, { useState, useCallback, useContext } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import ReportCards from '../components/reportCards';
import Board from '../components/board';
import PersonalReport from '../components/personalReport';
import { VscAccount } from 'react-icons/vsc';
import { useAuth } from '../hooks/useAuthHook';
import axios from 'axios';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { GlobalContext } from '../context/GlobalContextComp';

const apiBaseUrl = process.env.REACT_APP_apiUrl;
// const apiBaseUrl = 'http://127.0.0.1:8000/';

export default function DashboardPage() {
    const [searchParams] = useSearchParams();
    const { globalState, setGlobalState, dashboardDates, setEECountFilter } =
        useContext(GlobalContext);
    const [locationId, setLocationId] = useState(globalState.locationId);
    const { logout } = useAuth();
    const [searchItems, setSearchItems] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [location, setLocation] = useState({});

    const getNewData = () => {
        axios.get(`${apiBaseUrl}/reportings/locations/search`).then(
            (result) => {
                setIsLoaded(true);
                setSearchItems(result.data);
                console.log('result.data=', result.data)
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                setIsLoaded(true);
                setError(error);
            },
        );
    };

    React.useEffect(() => {
        const urlLocId = searchParams.get('locationId');
        if (urlLocId) {
            setLocationId(urlLocId);

            getLocationDetails(urlLocId);
        }
    }, []);

    const getLocationDetails = async (locationId) => {
        try{
            const response = await axios.get(`${apiBaseUrl}/location/${locationId}`);
            console.log('location_details=',response.data )
            setLocation(response.data);
        }catch(e){
            console.log(e);
        }
    }

    const getNewDataCallBack = useCallback(() => {
        getNewData();
    }, []);

    React.useEffect(() => {
        getNewDataCallBack();
    }, [getNewDataCallBack]);

    const handleOnSearch = (string, results) => {
        console.log('handleOnSearch', string, results);
    };

    const handleOnHover = (result) => {
        console.log('handleOnHover', result);
    };

    const handleOnSelect = (item) => {
        setLocationId(item.id);
        setGlobalState((globalState) => {
            return { ...globalState, locationId: item.id };
        });
        window.location.href = `/board?locationId=${item.id}`;
        console.log('handleOnSelect', item);
    };

    const handleOnFocus = () => {
        console.log('Focused');
    };

    const handleOnClear = () => {
        setLocationId(null);
        console.log('Cleared');
    };

    const formatResult = (item) => {
        console.log('result', item)
        return (
            <div className="result-wrapper">
                {/* <span className="result-span">locationId: {item.id}</span> */}
                <span className="result-span">name: {item.name}</span>
            </div>
        );
    };

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <div className="flex flex-col items-center bg-black">
                {/* <!-- Header/Navbar --> */}
                <div className="navbar" style={{ backgroundColor: '#fff' }}>
                    <div className="flex-1">
                        <img
                            onClick={() =>
                                (window.location.href = '/dashboard')
                            }
                            src="logo.png"
                            style={{ width: '100px', cursor: 'pointer' }}
                        />
                        {/* <button className="btn btn-ghost normal-case text-xl text-red-400">
                        </button> */}
                    </div>
                    <div className='flex-1'>
                    <h4 style={{fontWeight: 'bold'}}>{location?.name}</h4>
                    </div>
                    <div className="flex-none gap-2">
                        <div className="form-control" style={{ width: 400 }}>
                            <ReactSearchAutocomplete
                                className="input input-bordered"
                                items={searchItems}
                                onSearch={handleOnSearch}
                                onHover={handleOnHover}
                                onSelect={handleOnSelect}
                                onFocus={handleOnFocus}
                                onClear={handleOnClear}
                                styling={{ zIndex: 1 }}
                                formatResult={formatResult}
                                autoFocus
                            />
                        </div>
                        <div className="dropdown dropdown-end">
                            <label
                                tabIndex={0}
                                className="btn btn-ghost btn-circle avatar">
                                <div className="w-10 rounded-full">
                                    <VscAccount size={40} color="black"/>
                                </div>
                            </label>
                            <ul
                                tabIndex={0}
                                className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52">
                                <li>
                                    <Link
                                        className="justify-between"
                                        to="/onboarding">
                                        Onboarding
                                        <span className="badge">New</span>
                                    </Link>
                                </li>
                                <li>
                                    <button onClick={(e) => logout()}>
                                        Logout
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* <!-- Main Content --> */}
                <div className="mt-1 container mx-auto">
                    {/* <!-- Main Content Area --> */}
                    <label class="text-white">Filter with eecount</label>
                    <br />
                    <div class="flex">
                    <div class="text-white mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
                            <input
                                class="relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                                type="radio"
                                name="eecount"
                                id="inlineRadio1"
                                onChange={() => window.location.reload()}
                                value=""
                            />
                            <label
                                class="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                                for="inlineRadio1">
                                {' '}Reset
                            </label>
                        </div>

                        <div class="text-white mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
                            <input
                                class="relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                                type="radio"
                                name="eecount"
                                id="inlineRadio1"
                                onChange={(e) => setEECountFilter(e.target.value)}
                                value="< 500"
                            />
                            <label
                                class="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                                for="inlineRadio1">
                                {' '}
                                {'<'}500
                            </label>
                        </div>

                        <div class="text-white mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
                            <input
                                class="relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                                type="radio"
                                name="eecount"
                                id="inlineRadio2"
                                onChange={(e) => setEECountFilter(e.target.value)}
                                value="BETWEEN 500 AND 1000"
                            />
                            <label
                                class="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                                for="inlineRadio2">
                                {' '}
                                between 500 and 1000
                            </label>
                        </div>

                        <div class="text-white mb-[0.125rem] inline-block min-h-[1.5rem] pl-[1.5rem]">
                            <input
                                class="relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                                type="radio"
                                name="eecount"
                                id="inlineRadio3"
                                onChange={(e) => setEECountFilter(e.target.value)}
                                value="> 1000"
                            />
                            <label
                                class="mt-px inline-block pl-[0.15rem]"
                                for="inlineRadio3">
                                {' '}
                                1000+{' '}
                            </label>
                        </div>
                    </div>
                    <ReportCards
                        locationId={locationId}
                        dashboardDates={dashboardDates}
                    />
                    <Board locationId={locationId} />
                    <PersonalReport locationId={locationId} />
                </div>
            </div>
        );
    }
}
