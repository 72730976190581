import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { GlobalContext } from '../context/GlobalContextComp';
const apiBaseUrl = process.env.REACT_APP_apiUrl;

export default function ReportCards(props) {
    const { locationId, dashboardDates } = props;
    const {averageProspectSize, soldCases, eecountFilter} = useContext(GlobalContext);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(true);
    const [dashboardData, setDashboardData] = useState([]);
    console.log('locationId', locationId);
    // console.log('dashboardDates', dashboardDates);

    useEffect(() => {
        getNewData(eecountFilter);
    }, [eecountFilter]);

    const getNewData = (eecount = null) => {
        let location = locationId ? locationId : 'all';
        let reqBody = {
            where: {
                locationId: location,
                eecount
            },
        };
        console.log('reqbody',reqBody)
        axios.post(`${apiBaseUrl}/reportings/dashboardV2`, reqBody).then(
            (result) => {
                setIsLoaded(true);
                console.log('dashboard data',result.data)
                setDashboardData(result.data);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                setIsLoaded(true);
                setError(error);
            },
        );
    };

    React.useEffect(() => {
        getNewData();
    }, [locationId]);
    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <div className="container mx-auto justify-center items-center pt-10">
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-4 gap-6 mb-4">
                        <div className="flex flex-col justify-items-stretch border-4 BorderColor rounded-2xl overflow-hidden">
                            <div className="flex flex-col justify-items-stretch">
                                <div className="flex justify-center items-center text-white text-2xl p-6 bg-black">
                                    PQL
                                </div>
                                <div className="flex justify-center text-6xl border-2 border-gray-300 p-6 bg-gray-100">
                                    {dashboardData.total_pql || 0}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col justify-items-stretch border-4 BorderColor rounded-2xl overflow-hidden">
                            <div className="flex flex-col justify-items-stretch">
                                <div className="flex justify-center items-center text-white text-2xl p-6 bg-black">
                                    Total Sales Pipeline
                                </div>
                                <div className="flex justify-center text-6xl border-2 border-gray-300 p-6 bg-gray-100 ">
                                    {dashboardData.total_sales_pipeline || 0}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col justify-items-stretch border-4 BorderColor rounded-2xl overflow-hidden">
                            <div className="flex flex-col justify-items-stretch">
                                <div className="flex justify-center items-center text-white text-2xl p-6 bg-black">
                                    LOF
                                </div>
                                <div className="flex justify-center text-6xl border-2 border-gray-300 p-6 bg-gray-100 ">
                                    {dashboardData.total_lof || 0}
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col justify-items-stretch border-4 BorderColor rounded-2xl overflow-hidden">
                            <div className="flex flex-col justify-items-stretch">
                                <div className="flex justify-center items-center text-white text-2xl p-6 bg-black">
                                    Average Prospect Size
                                </div>
                                <div className="flex justify-center text-6xl border-2 border-gray-300 p-6 bg-gray-100 ">
                                    {dashboardData.avg_prospect_lives ? dashboardData.avg_prospect_lives.toFixed(2) : 0}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mx-auto mt-2">
                    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-4 gap-6">
                        <div className="flex flex-col justify-items-stretch border-4 BorderColor rounded-2xl overflow-hidden">
                            <div className="flex flex-col justify-items-stretch">
                                <div style={{fontSize: '21px'}} className="flex justify-center items-center text-white text-2xl p-6 bg-black">
                                    PQL + LOF Possible Lives
                                </div>
                                <div className="flex justify-center text-6xl border-2 border-gray-300 p-6 bg-gray-100 ">
                                    {dashboardData.pql_lof_poss_lives ? dashboardData.pql_lof_poss_lives : 0}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col justify-items-stretch border-4 BorderColor rounded-2xl overflow-hidden">
                            <div className="flex flex-col justify-items-stretch">
                                <div className="flex justify-center items-center text-white text-2xl p-6 bg-black">
                                    Total Lives
                                </div>
                                <div className="flex justify-center text-6xl border-2 border-gray-300 p-6 bg-gray-100 ">
                                    {dashboardData.total_lives || 0}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col justify-items-stretch border-4 BorderColor rounded-2xl overflow-hidden">
                            <div className="flex flex-col justify-items-stretch">
                                <div className="flex justify-center items-center text-white text-2xl p-6 bg-black">
                                    FWR Enrolled Lives
                                </div>
                                <div className="flex justify-center text-6xl border-2 border-gray-300 p-6 bg-gray-100 ">
                                    {dashboardData.fwr_enrolled_lives || 0}
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col justify-items-stretch border-4 BorderColor rounded-2xl overflow-hidden">
                            <div className="flex flex-col justify-items-stretch">
                                <div className="flex justify-center items-center text-white text-2xl p-6 bg-black">
                                    Sold Cases
                                </div>
                                <div className="flex justify-center text-6xl border-2 border-gray-300 p-6 bg-gray-100 ">
                                    {dashboardData.total_sold_case || 0}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
