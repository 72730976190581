import './App.css';
import React from 'react';

import { GrlobalContextProvider } from './context/GlobalContextComp';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import routes from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IconContext } from 'react-icons';
import { AuthProvider } from './hooks/useAuthHook';

export const UserContext = React.createContext();

export function App() {
    const router = createBrowserRouter(routes);

    return (
        <GrlobalContextProvider>
            <UserContext.Provider value="AEUser">
                <AuthProvider>
                    <IconContext.Provider
                        value={{
                            color: 'white',
                            style: { verticalAlign: 'middle' },
                            className: 'global-class-name',
                        }}>
                        <div className="">
                            <RouterProvider router={router} />
                            <ToastContainer />
                        </div>
                    </IconContext.Provider>
                </AuthProvider>
            </UserContext.Provider>
        </GrlobalContextProvider>
    );
}

export default App;
