import { useEffect } from 'react';
export default function Redirect() {
    const clientId = process.env.REACT_APP_ghlClientId;
    const redirectUri = process.env.REACT_APP_ghlRedirectUri;
    const ghlScopes = process.env.REACT_APP_ghlScopes;
    if (
        clientId === undefined ||
        redirectUri === undefined ||
        ghlScopes === undefined
    ) {
        console.log('App is missing some ENV Variable');
    }

    useEffect(() => {
        const authUrl = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=${'code'}&redirect_uri=${redirectUri}&client_id=${clientId}&scope=${ghlScopes}`;
        if (window.location.pathname === '/redirect') {
            console.log(authUrl);
            console.log(encodeURI(authUrl));
            window.location.href = encodeURI(authUrl);
        }
    }, [clientId, redirectUri, ghlScopes]);

    return null;
}
