import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import arrow from '../assets/White_Arrow_Up.png';
import axios from 'axios';
import Datepicker from 'react-tailwindcss-datepicker';
import { GlobalContext } from '../context/GlobalContextComp';

const apiBaseUrl = process.env.REACT_APP_apiUrl;

export default function Board(props) {
    const { dashboardDates, setdates, eecountFilter } = useContext(GlobalContext);
    const { locationId } = props;
    const [url] = useState(window.location.pathname);
    const [searchParams] = useSearchParams(url);
    const [offset, setOffset] = React.useState(0);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [order, setOrderBy] = React.useState('totalPql DESC');
    const [isDescending, setIsDescending] = useState(false);
    const [locations, setLocations] = useState([]);

    const handleOffsetChange = (event) => {
        setOffset((val) => {
            console.log('val', val);
            const isNext = event.target.name === 'next';
            return isNext ? val + 10 : val === 0 ? 0 : val - 10;
        });
        getNewDataCallBack();
        console.log('offset', offset);
    };

    const handleSorting = (event) => {
        setOrderBy(`${event} ${isDescending ? 'DESC' : 'ASC'}`);
        setIsDescending((currentState) => !currentState);

        // sort the data locations based on the event
        setLocations((locations) => {
            let locationsData = [...locations];
            const sortedLocation = locationsData.sort((a, b) => {
                if(!isDescending) return a[event] - b[event];
                if(isDescending) return a[event] + b[event];
                // if (a[event] - b[event]) {
                //     return -1;
                // }
                // if (a[event] + b[event]) {
                //     return 1;
                // }
                // return 0;
            });

            return !isDescending ? sortedLocation : sortedLocation.reverse();
        });

        // getNewDataCallBack();
    };

    const handleValueChange = (newValue) => {
        if (!newValue) {
            console.log('No value selected');
            getNewDataCallBack(undefined, undefined);
            return;
        }
        
        getNewDataCallBack(newValue.startDate, newValue.endDate);
        setdates({
            startDate: newValue.startDate ? new Date(newValue.startDate) : '',
            endDate: newValue.endDate ? new Date(newValue.endDate) : ''
        });
    };

    const getNewDataCallBack = useCallback(
        (startDate, endDate) => {
            const getNewData = () => {
                let filter = {
                    offset: offset,
                    // limit: 10,
                    // order: order,
                    // startDate: startDate ? startDate : dashboardDates.startDate,
                    // endDate: endDate ? endDate : dashboardDates.endDate,
                    eecountFilter,
                };
                axios
                    .post(`${apiBaseUrl}/reportings/getLeaderBoardV2`, filter)
                    .then(
                        (result) => {
                            console.log(result.data)
                            setLocations(result.data);
                            setIsLoaded(true);
                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            setIsLoaded(true);
                            setError(error);
                        },
                    );
            };
            getNewData();
        },
        [locationId, offset, searchParams, eecountFilter],
        // , dashboardDates
    );

    React.useEffect(() => {
        getNewDataCallBack();
    }, [getNewDataCallBack, offset, locationId, eecountFilter]);

    // console.log(
    //     'dashboardDates',
    //     dashboardDates.startDate,
    //     dashboardDates.endDate,
    // );

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <section className="mt-4">
                <div className="bg-black text-white p-4 rounded-lg">
                    <h3 className="text-2xl font-bold">CURRENT LEADERBOARD</h3>
                </div>
                <div className="p-4 ">
                    {false && (
                    <div className="flex justify-between">
                        <button
                            className="bg-black text-white px-4 py-2 rounded-lg BorderColor border-2"
                            name="previous"
                            onClick={handleOffsetChange}>
                            Previous
                        </button>
                        <Datepicker
                            containerClassName="m-4"
                            value={dashboardDates}
                            showShortcuts={true}
                            showFooter={true}
                            displayFormat={'MM/DD/YYYY'}
                            readOnly={false}
                            onChange={handleValueChange}
                        />
                        <button
                            className="bg-black text-white px-4 py-2 rounded-lg BorderColor border-2"
                            name="next"
                            onClick={handleOffsetChange}>
                            Next
                        </button>
                    </div>
                    )}
                </div>
                <div className="overflow-x-auto rounded-lg">
                    <table className="w-full bg-white border-collapse">
                        {/* equal width columns */}
                        <thead className="w-auto">
                            <tr>
                                <th className="py-4 px-6 bg-black text-white uppercase font-bold text-xs">
                                    {/* Heading 1 */}
                                </th>
                                <th
                                    className="py-4 px-6 bg-black text-white uppercase font-bold text-xs w-10"
                                    onClick={(e) => handleSorting('total_pql')}>
                                    <div className="flex flex-row items-center justify-center">
                                        PQL
                                        {order.includes('total_pql') ? (
                                            <img
                                                alt="arrow"
                                                className={
                                                    isDescending
                                                        ? `p-2 rotate-180`
                                                        : `p-2`
                                                }
                                                src={arrow}
                                                height={28}
                                                width={28}
                                            />
                                        ) : null}
                                    </div>
                                </th>
                                <th
                                    className="py-4 px-6 bg-black text-white uppercase font-bold text-xs w-10"
                                    onClick={(e) => handleSorting('total_lof')}>
                                    <div className="flex flex-row items-center justify-center">
                                        LOF
                                        {order.includes('total_lof') ? (
                                            <img
                                                alt="arrow"
                                                className={
                                                    isDescending
                                                        ? `p-2 rotate-180`
                                                        : `p-2`
                                                }
                                                src={arrow}
                                                height={28}
                                                width={28}
                                            />
                                        ) : null}
                                    </div>
                                </th>
                                <th
                                    className="py-4 px-6 bg-black text-white uppercase font-bold text-xs w-10"
                                    onClick={(e) => handleSorting('totalDm')}>
                                    <div className="flex flex-row items-center justify-center">
                                        DM Appts
                                        {order.includes('totalDm') ? (
                                            <img
                                                alt="arrow"
                                                className={
                                                    isDescending
                                                        ? `p-2 rotate-180`
                                                        : `p-2`
                                                }
                                                src={arrow}
                                                height={28}
                                                width={28}
                                            />
                                        ) : null}
                                    </div>
                                </th>
                                <th
                                    className="py-4 px-6 bg-black text-white uppercase font-bold text-xs w-10"
                                    onClick={(e) => handleSorting('total_sold_case')}>
                                    <div className="flex flex-row items-center justify-center">
                                        Sold Cases
                                        {order.includes('total_sold_case') ? (
                                            <img
                                                alt="arrow"
                                                className={
                                                    isDescending
                                                        ? `p-2 rotate-180`
                                                        : `p-2`
                                                }
                                                src={arrow}
                                                height={28}
                                                width={28}
                                            />
                                        ) : null}
                                    </div>
                                </th>
                                <th
                                    className="py-4 px-6 bg-black text-white uppercase font-bold text-xs w-10"
                                    onClick={(e) => handleSorting('total_fwr_lives')}>
                                    <div className="flex flex-row items-center justify-center">
                                        FWR Lives
                                        {order.includes('total_fwr_lives') ? (
                                            <img
                                                alt="arrow"
                                                className={
                                                    isDescending
                                                        ? `p-2 rotate-180`
                                                        : `p-2`
                                                }
                                                src={arrow}
                                                height={28}
                                                width={28}
                                            />
                                        ) : null}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {locations
                                ? locations.map((location, index) => (
                                      <tr
                                          key={index}
                                          className="odd:bg-red-800 even:bg-red-600 text-white">
                                          <td className="border px-6 py-4 w-10">
                                              <div className="flex flex-row justify-around">
                                                  <div className="p-2 w-2/8 flex items-center">
                                                      <div className="relative font-bold text-xl">
                                                          #{index + 1}
                                                      </div>
                                                  </div>
                                                  <div className="w-6/8 flex flex-col justify-center items-center">
                                                      <img
                                                          className="relative"
                                                        //   src={`${process.env.REACT_APP_apiUrl}/image/${location.locationId}`}
                                                          src = {location.image_url}
                                                          alt="image"
                                                          height={50}
                                                          width={50}
                                                          style={{borderRadius: '100%'}}
                                                      />
                                                      <a href={`/board?locationId=${location.id}`}>{location.name}</a>
                                                  </div>
                                              </div>
                                          </td>
                                          <td className="border px-6 py-4 w-10 ">
                                              {location.total_pql}
                                          </td>
                                          <td className="border px-6 py-4 w-10 ">
                                              {location.total_lof}
                                          </td>
                                          <td className="border px-6 py-4 w-10">
                                              {location.totalDm}
                                          </td>
                                          <td className="border px-6 py-4 w-10">
                                              {location.total_sold_case}
                                          </td>
                                          <td className="border px-6 py-4 w-10">
                                              {location.total_fwr_lives}
                                          </td>
                                      </tr>
                                  ))
                                : null}
                        </tbody>
                    </table>
                </div>
            </section>
        );
    }
}
