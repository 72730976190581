import { Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuthHook';

export const ProtectedRoute = ({ children }) => {
    const { user } = useAuth();

    
    // user is not authenticated check below
    if (!user || (user.email === '' && user.token === ''))
        return <Navigate to="/" />;

    return children;
};
