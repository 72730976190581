import React, { useRef } from 'react';
import { signInUser } from '../../services/users.service';
import { useAuth } from '../../hooks/useAuthHook';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function LoginForm() {
    const useEmailRef = useRef();
    const usePasswordRef = useRef();
    const { user, login } = useAuth();

    const showToastMessage = (
        isError = true,
        message = 'UnauthorizedError',
    ) => {
        if (isError) {
            return toast.error(message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        useEmailRef.current.value = null;
        usePasswordRef.current.value = null;
        return toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    };

    const handleSubmit = async () => {
        const { token } = await signInUser({
            email: useEmailRef.current.value,
            password: usePasswordRef.current.value,
        });
        if (!token) {
            return showToastMessage(
                true,
                'Invalid email or password: UnauthorizedError !',
            );
        }

        login({
            email: useEmailRef.current.value,
            token: token,
        });
        showToastMessage(false, `Welcome ${useEmailRef.current.value}!`);
        return <Navigate to="/dashboard" />;
    };

    return (
        <section className="text-gray-600 body-font relative">
            <div className="container px-5 py-24 mx-auto">
                <div className="flex flex-col text-center w-full mb-12">
                    <h1 className="sm:text-4xl text-6xl font-bold title-font text-gray-900">
                        Enter details below to Login
                    </h1>
                </div>

                {/* Form starts below */}
                <div className="lg:w-1/2 md:w-2/3 mx-auto">
                    <div className="flex flex-wrap items-center justify-center -m-2">
                        <div className="p-2 w-1/2 flex flex-wrap items-center">
                            <div className="form-control w-full max-w-xs">
                                <label className="label">
                                    <span className="label-text">
                                        What is your email?
                                    </span>
                                    <span className="label-text-alt">
                                        Required
                                    </span>
                                </label>
                                <input
                                    type="email"
                                    placeholder="Type here"
                                    className="input input-bordered w-full max-w-xs"
                                    id="email"
                                    ref={useEmailRef}
                                    name="email"
                                />
                            </div>
                        </div>
                        <div className="p-2 w-1/2 flex flex-wrap items-center">
                            <div className="form-control w-full max-w-xs">
                                <label className="label">
                                    <span className="label-text">
                                        What is your password?
                                    </span>
                                    <span className="label-text-alt">
                                        Required
                                    </span>
                                </label>
                                <input
                                    type="password"
                                    placeholder="Type here"
                                    className="input input-bordered w-full max-w-xs"
                                    id="password"
                                    ref={usePasswordRef}
                                    name="password"
                                />
                            </div>
                        </div>

                        <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
                            <button
                                onClick={handleSubmit}
                                className="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                                {`Log me in :)`}
                            </button>
                        </div>
                    </div>
                </div>

                {/* form ends above */}
            </div>
        </section>
    );
}
