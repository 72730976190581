import { createContext, useState, useEffect } from 'react';
import { format, addMonths, subMonths } from 'date-fns';

const GlobalContext = createContext({});
const createDatewithLastMonth = (date) => {
    const currentMonth = subMonths(date, 1);
    let newDateLastMonth = currentMonth;
    return newDateLastMonth;
};

const GrlobalContextProvider = (props) => {
    const [globalState, setGlobalState] = useState({
        user: {
            auth_Code: '',
        },
        locationId: '',
    });

    const [onBoardingState, setOnBoardingState] = useState();
    const [averageProspectSize, setAverageProspectSize] = useState();
    const [soldCases, setSoldCases] = useState();
    const [eecountFilter, setEECountFilter] = useState();

    let today = new Date();
    let todayLastmonth = createDatewithLastMonth(today);

    const [dashboardDates, setDashboardDates] = useState({
        startDate: "",
        endDate: "",
    });

    useEffect(() => {
        const userInContext = globalState.user.auth_Code ? true : false;
        if (userInContext) {
            return;
        } else {
            setGlobalState((g) => {
                return {
                    ...g,
                    user: {
                        user_id: localStorage.getItem('user_id'),
                        auth_Code: localStorage.getItem('auth_code'),
                    },
                    locationId: localStorage.getItem('location_id'),
                };
            });
        }

        const onBoardingStateInContext = onBoardingState ? true : false;
        if (onBoardingStateInContext) {
            return;
        } else {
            setOnBoardingState((g) => {
                return {
                    ...g,
                    onBoardingState: {
                        onBoardingState:
                            localStorage.getItem('onBoardingState'),
                    },
                };
            });
        }
    }, []);

    const setdates = (dates) => {
        setDashboardDates(dates);
    }

    // component warped with the .Provider
    return (
        <GlobalContext.Provider
            value={{
                globalState,
                setGlobalState,
                onBoardingState,
                setOnBoardingState,
                dashboardDates,
                setdates,
                setAverageProspectSize,
                averageProspectSize,
                setSoldCases,
                soldCases,
                setEECountFilter,
                eecountFilter,
            }}>
            {props.children}
        </GlobalContext.Provider>
    );
};

export { GlobalContext, GrlobalContextProvider };
